<template>
  <div class="border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-500 p-2">
    <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
      <li class="mr-2 cursor-pointer" v-for="(item, index) in items" :key="index">
        <div class="inline-flex items-center justify-center p-4 border-b-2  rounded-t-lg group" :class="item.name === route.name ? 'text-blue-600  border-blue-600  active dark:text-blue-500 dark:border-blue-500' : 'border-transparent  hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300' " @click="onRedirect(item.name)">
          <span :class="item.icon"/>
          <span>{{item.label}}</span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
const items = ref([
  {
    label: 'Pendientes',
    icon: 'pi pi-fw pi-shopping-cart',
    name: 'pharmasan.inventario.operaciones-stock.recepcion-tecnica'
  },
  {
    label: 'Historial',
    icon: 'pi pi-fw pi-list',
    name: 'pharmasan.inventario.operaciones-stock.recepcion-tecnica.historial'
  }
])
const onRedirect = (name) => {
  router.push({ name: name })
}
</script>
